@import "../../styles/colors.scss";

.Footer {
    background-color: $color__primary;
    color: $color__white;
    padding: 2rem 0;

    &__inner {
        margin: 2rem auto;
        width: 80%;
        max-width: 1500px;
        display: flex;
        align-items: center;
        gap: 4rem;
    }

    &__bold {
        font-weight: bold;
    }

    &__link {
        color: $color__white;
        text-decoration: none;

        &:hover {
            color: $color__gray--1;
        }
    }
}

@media screen and (max-width: 700px) {
    .Footer {
        &__inner {
            flex-direction: column;
            gap: 1rem;
        }
    }
}