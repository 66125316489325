@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Banner {
    width: 100%;
    padding: 1rem 20vw;
    background-color: rgba($color__secondary, 0.8);
    text-align: center;
    color: $color__white;
    bottom: 0;

    &__text {
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        font-weight: 300;
        line-height: 1.5rem;
        color: $color__black;
    }

    &__icon {
        font-size: $fs-m;
        margin-right: 0.3rem;
        display: flex;
        align-items: center;
    }
}
