@import "./styles/fonts.scss";
@import "./styles/colors.scss";

body {
    font-family: $ff;
    font-size: 16px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        width: 80%;
        height: 65vh;
        background-color: rgba($color__primary, 0.1);
    }
}

.App {
    overflow-x: hidden;
}

h1 {
    font-size: $fs-xl;
}

h2 {
    font-size: $fs-l;
}

h3 {
    font-size: $fs-l;
}

p {
    font-size: $fs-s;
    font-weight: 300;
    line-height: 0.5rem;
}

a {
    color: $color__primary;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}