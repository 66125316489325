@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Hero {
    display: flex;
    flex-direction: row;
    min-height: 80vh;

    &__left,
    &__right {
        flex: 1 0 auto;
        width: 50%;
    }

    &__left {
        display: flex;
        justify-content: right;

        &Inner {
            max-width: 450px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4rem 2rem;
        }
    }

    &__right {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:before {
            content: "";
            display: block;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba($color__primary, 0.1) 100%);
            height: 100%;
        }
    }

    &__link {
        display: inline-block;
        background-color: $color__primary;
        color: $color__white;
        text-decoration: none;
        padding: 1rem;
        border-radius: 2px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 2rem 0;

        &:hover {
            background-color: darken($color__primary, 3);
        }
    }

    h1 {
        font-size: $fs-xl;
        line-height: 135%;
        letter-spacing: 0.1rem;

        span {
            color: $color__primary;
        }
    }
}

@media screen and (max-width: 700px) {
    .Hero {
        &__right {
            display: none;
        }
    }
}
