@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$ff: 'Roboto', sans-serif;
$ff-serif: 'PT Serif', serif;

$fs-s: calc(1rem / 16 * 16);
$fs-m: calc(1rem / 16 * 18);
$fs-l: calc(1rem / 16 * 24);
$fs-xl: calc(1rem / 16 * 42);
$fs-xxl: calc(1rem / 16 * 72);
$fs-xxxl: calc(1rem / 16 * 92);