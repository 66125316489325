@import "../../styles/fonts.scss";

.Header {
    padding: 1.5rem 2rem;

    span {
        font-family: $ff-serif;
        font-weight: bold;
        font-size: $fs-l;
    }
}