@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.Content {
    padding: 2rem 0;
    position: relative;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        margin: 2rem auto;
        width: 80%;
        max-width: 1000px;
    }

    &__left,
    &__right {
        display: inline-block;
        flex: 1 1 auto;
        box-sizing: border-box;
    }

    &__h3 {
        font-weight: 200;
        font-size: $fs-xxl;
    }

    &__h4 {
        font-size: $fs-xl;
        font-weight: 300;
        margin: 0 0 2rem;
    }

    &__text {
        line-height: 1.2;
        letter-spacing: 1px;

        &--primary {
            color: $color__primary;
        }

        &--bold {
            font-weight: 700;
        }
    }

    &__right {
        max-width: 25%;
        font-style: italic;
        position: relative;
        color: $color__white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: -35%;
            right: 10rem;
            height: 27rem;
            width: 27rem;
            background-color: rgba($color__secondary, 0.8);
            z-index: -1;
            transform: rotate(45deg);
        }

        &:after {
            background-color: $color__primary;
            height: 25rem;
            width: 25rem;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
        }

        a {
            display: block;
        }
    }

    &__left {
        /*border-radius: 0.5rem;
        background-color: rgba($color__white, 0.7);*/
    }

    &__img {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .Content {
        padding-top: 2rem;

        &__inner {
            flex-direction: column;
            width: 90%;
            gap: 2rem;
            margin-top: 0;
        }

        &__right {
            max-width: 100%;

            &:before,
            &:after {
                bottom: -150%;
                right: 9rem;
                height: 20rem;
                width: 20rem;
                transform: translate(50%, -50%) rotate(-25deg);
            }

            &:after {
                top: 50%;
                right: 50%;
                height: 23rem;
                width: 23rem;
            }
        }
    }
}
